/*
.star-1 {
    position: absolute;
    opacity: 0;
    top: 20%;
    left: 20%;
    width: 18px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.star-2 {
    position: absolute;
    opacity: 0;
    top: 45%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-3 {
    position: absolute;
    opacity: 0;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-4 {
    position: absolute;
    opacity: 0;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-5 {
    position: absolute;
    opacity: 0;
    top: 25%;
    left: 45%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-6 {
    position: absolute;
    opacity: 0;
    top: 5%;
    left: 50%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all .8s ease;
}*/

.star-fade {
    animation: fadeIn 20s linear infinite;
}

.star-1 {
    position: absolute;
    opacity: 1;
    top: 30%;
    left: -35%;
    width: 18px;
    height: auto;
    animation: blink 2.7s linear infinite;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
}

.star-2 {
    position: absolute;
    opacity: 1;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    animation: blink 2.3s linear infinite;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
}

.star-3 {
    position: absolute;
    opacity: 0.9;
    top: 65%;
    left: 0%;
    width: 10px;
    height: auto;
    animation: blink 2.3s linear infinite;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
}

.star-4 {
    position: absolute;
    opacity: 0.9;
    top: 70%;
    left: 73%;
    width: 18px;
    height: auto;
    animation: blink 3.1s linear infinite;
    filter: drop-shadow(0 0 10px #f2efff);
    z-index: 2;
}

.star-5 {
    position: absolute;
    opacity: 0.9;
    top: -5%;
    left: 90%;
    width: 15px;
    height: auto;
    animation: blink 1.9s linear infinite;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
}

.star-6 {
    position: absolute;
    opacity: 0.9;
    top: -20%;
    left: 70%;
    width: 10px;
    height: auto;
    animation: blink 1.7s linear infinite;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
}

.fil0 {
    fill: #e12427
}

.fil1 {
    fill: #06559e
}

@keyframes blink {
    0% {
        opacity: 0.7;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0.7;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    19%  {
        opacity: 0.8;
    }

    20% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0.0;
    }
}