body {
  margin: 10px auto;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  font-size: 0.8rem !important;
  background-color: #fff;
  width: 1100px;
  padding: 20px 20px 0 20px !important;
  overflow-y: scroll !important;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(104, 101, 101, 0.11),
    0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11),
    0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11),
    0 32px 32px rgba(0, 0, 0, 0.11);
}

html {
  background-color: rgba(228, 227, 219, 0.3);
  /*
  background: linear-gradient(90deg, #3475b0, #e12427, #3475b0, #e12427);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;*/
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}