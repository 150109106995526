a:hover {

  color: #183851 !important;
}

.step_1_input {
  background-color: white;
  border-radius: 4px;
}

.MuiOutlinedInput-input {
  height: 0.8em;
  padding: 9px 1px 9px 9px;

}


#comment-input.MuiOutlinedInput-input {
  padding: 0px;
}

.MuiSelect-select {
  padding-top: 5px;
  padding-bottom: 5px;
}

.MuiCardContent-root:last-child {
  padding-bottom: 10px;
}

#principle_image {
  position: relative;
}

#check_valve {
  top: 65px;
  left: 216px;
  position: absolute;
}

#check_valve_PR2 {
  top: 149px;
  left: 133px;
  position: absolute;
  height: 23px;
  width: 55px;
}

#check_valve_PR2_Sabo {
  top: 82px;
  left: 205px;
  position: absolute;
  height: 55px;
  width: 23px;
}

#check_valve_PR3 {
  top: 65px;
  left: 141px;
  position: absolute;
}

#regulate_valve {
  top: 21px;
  left: 46px;
  position: absolute;
}

#regulate_valve_PR2 {
  top: 137px;
  left: 355px;
  position: absolute;
}

#regulate_valve_PR3 {
  top: 139px;
  left: 355px;
  position: absolute;
}

#regulate_valve_VA1 {
  top: 21px;
  left: 354px;
  position: absolute;
}

#va_left_title {
  top: 90px;
  left: 90px;
  position: absolute;
}

#va_right_title {
  top: 90px;
  left: 278px;
  position: absolute;
}


#logo {
  height: 30px;
  width: 184px;
}

.help_icon {
  color: #06559e;
  font-size: 20px;
}

.help_icon_small {
  color: #06559e;
  font-size: 10px;
}

.shunt-percent {
  margin-left: 26px !important;
}

.back_valve {
  top: 71px;
  left: 255px;
  position: absolute;
  font-size: 11px;
}

.main_pump {
  top: 72px;
  left: 57px;
  position: absolute;
  font-size: 11px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 4px;
}

.css-1a5icme {
  padding: 4px;
}

.css-164cm7o-MuiButtonBase-root-MuiRadio-root {
  padding: 4px;
}

.css-1s59z6c {
  padding: 4px;
}

.element {
  height: env(safe-area-inset-top);
  width: env(safe-area-inset-left);
  margin: env(safe-area-inset-right);
  padding: env(safe-area-inset-bottom, 20px);
}


.error-box {
  width: '700px';
  justify-content: 'center';
  padding: '10px 20px';
  height: 'fit-content';
  background-color: #e12427bf;
  box-shadow: 2px 2px 20px #0055a040;
  border: 3px solid white;
  border-radius: 0;
  background-image: url(./assets/images/error_sign.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.green-level-box {
  width: '700px';
  justify-content: 'center';
  padding: '10px 20px';
  height: 'fit-content';
  box-shadow: 2px 2px 20px #0055a040;
  border-radius: 0;
  background-color: #44953966;
  border: 3px solid white;
}

.notice-box {
  width: '700px';
  justify-content: 'center';
  padding: '10px 20px';
  height: 'fit-content';
  background-color: #f7c03fbe;
  box-shadow: 2px 2px 20px #0055a040;
  border: 3px solid white;
  border-radius: 0;
  background-image: url(./assets/images/notice_icon.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.info-pump-selection {
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #DFE4E9;
}

.logo {
  width: 250px;
  height: auto;
  margin-left: 5px;
  margin-right: 200px;
}

.facility-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 194px;
}

.adjust-card {
  width: 500px;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 10px;
  height: fit-content;
  border-radius: 0;
  box-shadow: 2px 2px 10px #0055a030;
}

.textarea-box {
  border: 1px solid #e0e0e0;
  font-family: 'Roboto';
  padding: 5px;
  border-radius: 5px;
  line-height: 1.5;
  resize: vertical;
  width: 98%;
  font-size: .8rem;
}

.css-1fxc43j-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: -3px;
}

.css-jvrlpo-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled,
.css-1o6z5ng.Mui-disabled {
  background: aliceblue;
}

.css-1rs7l8x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled,
.css-5ah627.Mui-disabled {
  background: rgb(246, 245, 245);
}

.footer-h3 {
  margin-bottom: 0px !important;
  color: #183851;
}

.a_b_test {
  position: relative;
  top: -110px;
}

.preview-icon {
  color: rgb(95, 171, 237);
  margin-left: 4px;
  width: 14px !important;
  height: 14px !important;
}

.intro-preview-icon {
  color: rgb(95, 171, 237);
  width: 14px !important;
  height: 14px !important;
}

.edit-icon {
  color: darkgray;
  margin-left: 4px;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
}

.fresh-icon {
  color: rgb(95, 171, 237);
  margin-left: 2px;
  width: 14px !important;
  height: 14px !important;

}

.child-session-icon {
  color: rgb(95, 171, 237);
  margin-left: 5px;
  width: 14px !important;
  height: 14px !important;

}

.intro-session-icon {
  color: rgb(95, 171, 237);
  width: 14px;
  height: 14px;

}

.preview-icon-box {
  position: absolute;
  margin-left: 127px;
  margin-top: -16px;
}

.preview-icon-box-longID {
  position: absolute;
  margin-left: 155px;
  margin-top: -16px;
}

.overview-main-panel {
  display: flex;
  flex-direction: column;
  width: 900px;
}


div.MuiListItemText-root.MuiListItemText-dense.MuiListItemText-root span.MuiTypography-root.MuiTypography-body2.MuiListItemText-primary.MuiTypography-root {
  font-weight: normal;
  font-style: italic;
  color: #8a8a8a;
}

.Mui-selected.MuiListItem-root div.MuiListItemText-root.MuiListItemText-dense.MuiListItemText-root span.MuiTypography-root.MuiTypography-body2.MuiListItemText-primary.MuiTypography-root {
  font-weight: bold !important;
  font-style: normal !important;
  color: black;
}


.css-1u70uxe-MuiFormLabel-root-MuiInputLabel-root,
.css-tvn3vv {
  margin-top: -10px;
}

.css-jmeyxg-MuiFormLabel-root-MuiInputLabel-root,
.css-zraxz {
  margin-top: -4px;
}


.MuiListItem-root.Mui-selected {
  background-color: transparent;
}

.css-18k7o0v-MuiStepLabel-label.Mui-active,
.css-nk31xh.Mui-active {
  font-style: italic;
}

.handle-active-btn:disabled {
  background-color: white;
  color: #06559e;
  border: 1px solid #e12427;
}

ul.connection-text {
  list-style: none;
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2; */
  margin: 0px;
  padding-left: 0px;
}

ul.connection-text li:before {
  content: '\2714\0020';
  color: #06559e;
}

div#multiColumn {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
}

#multiColumn li:nth-child(8) {
  -webkit-column-break-after: always;
  break-after: always;
}

.check-icon {
  font-size: 11px;
  color: #06559e;
}

.check-icon-green {
  font-size: 11px;
  color: green;
}

.check-icon-green-help {
  font-size: 12px;
  color: green;
  margin-left: -1px;
}


.check-icon-flex {
  font-size: 11px;
  color: red;
}

.MuiListItemIcon-root {
  min-width: 25px;
  padding: 0 !important;
}

.con-type-list.MuiList-root {
  padding: 0 !important;
}

p.MuiListItemText-secondary {
  color: black;
}

.con-type-list-item {
  margin-top: 2px;
  margin-bottom: 2px;
}

.modal-size {
  min-height: 70vh;
  max-height: 70vh;
  top: 50vh;
  overflow-y: hidden !important;
}

.overview-content-size {
  overflow-x: auto;
  height: 67vh;
}

.Mui-disabled {
  color: (0, 0, 0, 0.38)
}

.numberIcon {
  color: #FFF !important;
  fill: #06559e !important;
  height: 1.4em;
  width: 1.3em;
}

.numberIcon-text {
  fill: #FFF !important;

}

.delete-btn {
  min-width: 36px;
  padding: 0px;
}

.delete-btn:hover {
  color: #c5001A;
  background-color: transparent;
}

.comment-btn {
  min-width: 57px;
  padding: 0px;
}

.comment-btn:hover {
  color: #06559ec8;
  background-color: transparent;
}

.models-btn {
  color: #888888;
}

.models-btn:hover {
  color: #06559e;
  background-color: transparent;
}

.models-btn:active {
  color: #06559e;
}

.shunt-table-header,
.css-fq4nlt,
.css-1yeen6l-MuiTableCell-root {
  padding: 7px 11px !important;
  text-align: left;
}

.table-cell {
  padding: 5px 5px;
  line-height: 0.9;
  text-align: left;
}

.pointer {
  cursor: pointer;
}


.table-cell-admin {
  padding: 3px 3px;
  line-height: 0.9;
}

.table-cell.wide {
  padding-right: 2px;
}

h1.welcome-title::after {
  content: "";
  display: block;
  margin: .5em 0;
  background: #e12427;
  height: 3px;
  width: 80px;
}

h2.welcome-title::after {
  content: "";
  display: block;
  margin: .5em 0;
  background: #e12427;
  height: 3px;
  width: 80px;
}

h2.intro-title::after {
  content: "";
  display: block;
  margin: .5em 0;
  background: #e12427;
  height: 3px;
  width: 80px;
}

h2.header-title::after {
  content: "";
  display: block;
  margin: .3em 0;
  background: #e12427;
  height: 2px;
  width: 60px;
}

h3.header-title::after {
  content: "";
  display: block;
  margin: .3em 0;
  background: #e12427;
  height: 2px;
  width: 60px;
}

h3.intro-subheaders {
  font-size: 0.8rem;
  margin-bottom: -12px;
}

.divider-sidepanel {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

/*registred mark */
sup {
  position: relative;
  font-size: 0.85em;
  line-height: 0;
  vertical-align: super;
  top: 0em;
}

.MuiTableRow-hover:hover {
  background-color: rgba(67, 149, 57, 0.14) !important;
}

/* rgba(247, 192, 63, 0.14) !important; rgba(6, 85, 158, 0.4)*/
.Mui-selected {
  background-color: rgba(240, 248, 255, 1) !important;
  /* border-left: 2px solid #06559e;  */
}

.stripedDiv-loading {
  color: white;
  fill: none;
  stroke: black;
  stroke-linejoin: round;
  stroke-width: .5px;
  background-image: repeating-linear-gradient(45deg,
      #f3d6d83d,
      #b1d0ff 38px,
      #b1d0ff3d 50px,
      #f3d6d83d 55px,
      #f3d1d3 80px,
      #f3d6d83d 105px);
  background-size: 200% 100%;
  animation: animate-loading 2s linear infinite;
}

@keyframes animate-loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -147px 0px;
  }
}

.info-box {
  padding-top: 12px !important;
  line-height: 1.43;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.logo-container-inspect {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: -10px;
}

.validation {
  color: #c5001A;
  text-align: center;
  margin-bottom: 0px;
}

.forgot-pass {
  text-transform: none;
  font-style: italic;
  text-align: center;
  margin-top: 5px;
}

button.danger-btn {
  background-color: #c5001A;
}

button.danger-btn:hover {
  background-color: #920013;
}

button.danger-btn-outlined {
  border: 1px solid #c5001A;
  color: #c5001A;
}

button.danger-btn-outlined:hover {
  background-color: #9200132c;
}

button.danger-btn-outlined:disabled {
  color: #a0787d;
  border: 1px solid #7e4d4d;
  background-color: #9e9e9e1e;
}

/* .drift-input {
  
   input::-webkit-inner-spin-button, input::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }
} */

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* {  Chrome, Safari, Edge, Opera} */
.drift-input input[type=number]::-webkit-outer-spin-button,
.drift-input input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


/* Firefox */
.drift-input input[type=number] {
  -moz-appearance: textfield;
}



@media screen and (max-height: 1200px) {
  .modal-size {
    top: 55vh;

  }

}

@media screen and (max-height: 837px) {
  .modal-size {
    top: 60vh;
    min-height: 560px;
    max-height: 570px;
    overflow-y: hidden !important;
  }

  .overview-content-size {

    height: 556px;
  }

}

@media screen and (max-height: 745px) {
  .modal-size {
    top: 50vh;
    min-height: 560px;
    max-height: 570px;
    overflow-y: hidden !important;
    padding-bottom: 40px;
  }

  .overview-content-size {

    height: 557px;

  }

}

.mc-x3d {
  width: 250px;
  height: 330px;
  border: 0;
  background-color: rgba(102, 102, 102, 0.04);
}

.colored {
  background: #0075FF;
  width: 100px;
  height: 100px;
  position: relative;
}

.colored:hover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}


.fab-animation {
  color: #fff;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: 2;
    box-shadow: 0 0 20px 20px #06559e;
  }

  &::after {
    width: 30px;
    height: 30px;
    background-color: #06559e;
    animation: circle-size 0.8s linear infinite alternate;
  }

  &-icon {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(5);
    display: block;
    width: 16px;
    height: 12px;
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    border: 2px solid;
    border-top: 0;
    margin-top: 20px;
    z-index: 3;

    &::before,
    &::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
    }

    &::before {
      width: 2px;
      height: 5px;
      top: calc(100% + 1px);
      left: 50%;
      transform: translate(-50%, 0);
      background-color: #fff;
    }

    &::after {
      border: 2px solid;
      width: 8px;
      height: 18px;
      left: 50%;
      top: -10px;
      border-radius: 4px;
      transform: translate(-50%, 0);
    }
  }

  &-shadow {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    box-shadow: 10px -55px 30px 15px #823ca6, 24px -10px 47px 10px #aab3d2, -21px -25px 97px 10px #5acee3, 51px 5px 17px 10px #1b7d8f, 3px 2px 77px 10px #f30bf5;
    animation: shadow-rotate 1.5s linear infinite;
    transform-origin: center;
  }
}

@keyframes circle-size {
  from {
    width: 10px;
    height: 10px;
  }

  to {
    width: 30px;
    height: 30px;
  }
}


@keyframes shadow-rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}